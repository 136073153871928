import http from "../http-common"

class GoogleAuthService {

    authenticate(token){
        return http.post('/api/login', token)
    }

    verify(token){
        return http.post('/api/cookie', token)
    }

    logout(token){
        return http.post('/api/logout', token)
    }
    getRole(token) {
        return http.get(`api/roles`, {
            headers: {
                Authorization: token
            }
        }); 
    }
    getMtmUsers(role, token) {
        return http.get(`api/mtmusers/${role}`, {
            headers: {
                Authorization: token
            }
        }); 
    }

    getMtmUsersFast(role, token) {
        return http.get(`api/mtmusers_fast/${role}`, {
            headers: {
                Authorization: token
            }
        }); 
    }
    addCampaignToUser(toAdd, token){
        return http.post(`api/campaign_permission`, toAdd, {
            headers: {
                Authorization: token
            }
        }); 
    }
    removeCampaignsfromUser(items, token){
        return http.post(`api/campaign_permission_remove`, items, {
            headers: {
                Authorization: token
            }
        }); 
    }
    updateUser(user,id, token){
        return http.put(`api/mtm_user/${id}`, user, {
            headers: {
                Authorization: token
            }
        }); 
    }
}

export default new GoogleAuthService(); 