<template>
  <v-app class="v-app">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400&display=swap');

.v-app{
  font-family: Lexend Deca !important;
}
</style>
