import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';
import "../../style/css/variables.css";

Vue.use(Vuetify, {
    options: {
        customProperties: true
      }
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#31a5bc',
                secondary: '#FFFFFF',
                accent: '#FF8300',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                onboarding: '#2196F3',
                done: '#31a5bc',
                paused: '#f0c518',
            }
        }
    }
});
